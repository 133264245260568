export const tableSkeletonLoaderColumns = (size = 4) => {
  return Array.from({ length: size }).map(() => ({
    style: {
      flex: '1'
    },
    cell: () => (
      <div className="h-4 w-full animate-pulse rounded bg-brand-50"></div>
    )
  }));
};
