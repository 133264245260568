import React from 'react';
import Select from 'react-select';

interface Field {
  name: string;
  type: 'text' | 'email' | 'password' | 'number' | 'textarea' | 'select';
  label: string;
  placeholder: string;
  required?: boolean;
  divClassName?: string;
  inputClassName?: string;
  selectClassName?: string;
  options?: { value: string; label: string }[]; // Options for select
}

interface FormProps {
  fields: Field[];
  onSubmit: (values: Record<string, string>) => void;
  submitLabel: string;
  submitClass?: string;
  children?: React.ReactNode; // For additional components like "Sign in with Outlook"
}

const Form: React.FC<FormProps> = ({
  fields,
  onSubmit,
  submitLabel,
  submitClass = 'w-full',
  children
}) => {
  const [formValues, setFormValues] = React.useState<Record<string, string>>(
    Object.fromEntries(fields.map((field) => [field.name, '']))
  );

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleSelectChange = (name: string, selectedOption: any) => {
    setFormValues((prev) => ({
      ...prev,
      [name]: selectedOption?.value || ''
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(formValues);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      {fields.map((field) => (
        <div key={field.name} className={`${field.divClassName}`}>
          <label
            htmlFor={field.name}
            className="text-gray-700 block text-sm font-medium"
          >
            {field.label}{' '}
            {field.required && <span className="text-red-500">*</span>}
          </label>
          {field.type === 'textarea' ? (
            <textarea
              id={field.name}
              name={field.name}
              placeholder={field.placeholder}
              required={field.required}
              value={formValues[field.name]}
              onChange={handleChange}
              className="border-gray-300 mt-1 block w-full rounded-lg border px-3 py-2 text-sm shadow-sm focus:border-brand focus:ring-brand"
            />
          ) : field.type === 'select' ? (
            <Select
              id={field.name}
              name={field.name}
              options={field.options}
              placeholder={field.placeholder}
              onChange={(selectedOption) =>
                handleSelectChange(field.name, selectedOption)
              }
              className={`mt-1 block rounded-lg text-sm ${field.selectClassName ? field.selectClassName : 'w-full'}`}
              classNamePrefix="react-select"
            />
          ) : (
            <input
              id={field.name}
              name={field.name}
              type={field.type}
              placeholder={field.placeholder}
              required={field.required}
              value={formValues[field.name]}
              onChange={handleChange}
              className={`border-gray-300 mt-1 block rounded-lg border px-3 py-2 text-sm shadow-sm focus:border-brand focus:ring-brand ${field.inputClassName ? field.inputClassName : 'w-full'}`}
            />
          )}
        </div>
      ))}
      <button
        type="submit"
        className={`${submitClass} rounded-lg bg-brand px-4 py-2 text-white shadow hover:bg-brand-700 focus:ring-2 focus:ring-brand focus:ring-offset-2`}
      >
        {submitLabel}
      </button>
      {children}
    </form>
  );
};

export default Form;
