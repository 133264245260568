import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { states } from '../../redux/states/StateBuilder';

export const useResetState = (stateAction: string, deps = [], ms = 5000) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (deps.length > 0 && deps.some((dep) => !!dep)) {
      setTimeout(() => {
        dispatch({ type: states[stateAction].constants.reset });
      }, ms);
    }
  }, [...deps, ms]);
};
