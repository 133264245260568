import { ActionButtons } from '../../../components/ActionButtons';
import * as Yup from 'yup';

export const departmentColumns = (actions: any) => [
  {
    name: 'Name',
    selector: (row: any) => row?.name
  },
  {
    name: 'Company',
    selector: (row: any) => row?.company?.name
  },
  {
    name: 'Actions',
    type: 'actions',
    cell: (row: any) => [
      <ActionButtons row={row} key={row.id} actions={actions} />
    ]
  }
];

export const departmentValidationSchema = Yup.object().shape({
  name: Yup.string()
    .transform((value) => value.trim())
    .min(2, 'The name is too short!')
    .required('Name is required'),
  company: Yup.object({
    value: Yup.string().required('The company is required')
  })
});
