import { RouteObject } from 'react-router-dom';
import FeedsPage from '../pages/feeds';
import NewsLetterPage from '../pages/newsletters';
import EventsPage from '../pages/events';
import NotFoundPage from '../pages/NotFound';
import TeamPage from '../pages/team';
import { ProtectedRoute } from './ProtectedRoutes.tsx';
import LoginPage from '../pages/login';
import HomePage from '../pages/home';
import CompanyPage from '../pages/companies';
import DepartmentPage from '../pages/departments';
import Layout from '../layouts/Layout';

export const routes: RouteObject[] = [
  {
    path: '/',
    element: <LoginPage />
  },
  {
    path: '',
    element: <ProtectedRoute />,
    children: [
      {
        path: '',
        element: <Layout />, // Main layout wraps all authenticated pages
        children: [
          {
            path: '/home',
            element: <HomePage />
          },
          {
            path: '/team',
            element: <TeamPage />
          },
          {
            path: '/events',
            element: <EventsPage />
          },
          {
            path: '/newsletters',
            element: <NewsLetterPage />
          },
          {
            path: '/news-feed',
            element: <FeedsPage />
          },
          {
            path: '/subsidiaries',
            element: <CompanyPage />
          },
          {
            path: '/departments',
            element: <DepartmentPage />
          }
        ]
      }
    ]
  },
  {
    path: '*',
    element: <NotFoundPage />
  }
];
