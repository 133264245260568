import {
  get,
  isEmpty,
  join,
  map,
  split,
  startCase,
  toLower,
  toUpper
} from 'lodash';
import { StateCollection } from './index.ts';

function buildAction(entity: string): CVL.IConstant {
  const state = formatConstantName(entity);
  return {
    request: `${state}_REQUEST`,
    load: `${state}_LOAD`,
    success: `${state}_SUCCESS`,
    data: `${state}_DATA`,
    meta: `${state}_META`,
    error: `${state}_ERROR`,
    reset: `${state}_RESET`
  };
}

function formatConstantName(entity: string) {
  if (isEmpty(entity)) return '';

  const title = toUpper(entity);

  const formated = split(title, ' ');

  if (formated.length > 0) return join(formated, '_');

  return title;
}

function formatStateName(entity: string) {
  if (isEmpty(entity)) return '';

  const title = startCase(toLower(entity));

  const formated = split(title, ' ');

  if (formated.length > 0) return join(formated, '');

  return title;
}

function buildState(state: CVL.IStateSchema) {
  const { actions, entity } = state;

  const built: CVL.IBuildState = {}; // { add: {}}

  for (const key in actions) {
    if (get(actions, key).value) {
      built[`${key}${formatStateName(entity)}`] = {
        ...get(actions, key),
        constants: buildAction(`${formatConstantName(entity)}_${toUpper(key)}`)
      };
    }
  }

  return built;
}

export function stateSchema() {
  let states: CVL.IBuildState = {};

  // start working on states from here
  map(StateCollection, (s) => {
    states = { ...states, ...buildState(s) };
  });

  return states;
}

export const states = stateSchema();
