import React from 'react';

interface Action<T> {
  icon: React.ReactNode; // Icon component
  handler: (row: T) => void; // Function to handle the action
  label?: string; // Optional label for accessibility
}

interface ActionButtonsProps<T> {
  row: T; // Row data
  actions: Action<T>[]; // Array of actions
}

export const ActionButtons = <T,>({ row, actions }: ActionButtonsProps<T>) => {
  return (
    <div className="flex flex-row space-x-2">
      {actions.map((action, index) => (
        <button
          key={index}
          onClick={() => action.handler(row)}
          className="cursor-pointer rounded-lg p-2 transition-all duration-300 hover:scale-110 hover:bg-[#EAECF0]"
          aria-label={action.label || 'Action'}
        >
          {action.icon}
        </button>
      ))}
    </div>
  );
};
