import { useState } from 'react';

interface PaginationConfig {
  initialPage?: number;
  initialRowsPerPage?: number;
  totalItems?: number;
}

export const usePagination = (config: PaginationConfig = {}) => {
  const { initialPage = 1, initialRowsPerPage = 10, totalItems = 0 } = config;

  const [currentPage, setCurrentPage] = useState(initialPage);
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);
  const [totalRows, setTotalRows] = useState(totalItems);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = (
    newRowsPerPage: number,
    currentPage: number
  ) => {
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(currentPage);
  };

  const resetPagination = () => {
    setCurrentPage(initialPage);
    setRowsPerPage(initialRowsPerPage);
  };

  return {
    currentPage,
    rowsPerPage,
    totalRows,
    setTotalRows,
    setCurrentPage,
    handlePageChange,
    handleRowsPerPageChange,
    resetPagination
  };
};
