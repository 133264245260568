import { VERBS } from '../../api/RequestBuilder';

export const UsersState: CVL.IStateSchema = {
  entity: 'Users',
  actions: {
    create: {
      value: true,
      onSuccess: ['USERS_LIST_REQUEST'],
      api: {
        verb: VERBS.post,
        endpoint: '/users',
        auth: true
      }
    },
    list: {
      value: true,
      api: {
        verb: VERBS.get,
        endpoint: '/users?searchKey=:searchKey&page=:page&pageSize=:pageSize',
        auth: true
      }
    },
    update: {
      value: true,
      onSuccess: ['USERS_LIST_REQUEST'],
      api: {
        verb: VERBS.patch,
        endpoint: '/users/:id',
        auth: true
      }
    },
    delete: {
      value: true,
      onSuccess: ['USERS_LIST_REQUEST'],
      api: {
        verb: VERBS.delete,
        endpoint: '/users/:id',
        auth: true
      }
    }
  }
};
