import React from 'react';
import { useRoutes } from 'react-router-dom';
import { routes } from './Routes';

const MainLayout: React.FC = () => {
  const routing = useRoutes(routes);
  return <>{routing}</>;
};

export default MainLayout;
