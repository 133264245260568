import { combineReducers } from 'redux';
import { combinedReducers } from './ReducerBuilder';

const combined = combinedReducers();

const appReducer = combineReducers({
  ...combined
});

const rootReducer = (state: any, action: any) => {
  if (action.type === 'USER_LOGOUT') {
    // Clear state on logout
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;

/**
 * This is needed when using `useSelector` hook in TypeScript.
 *
 * @link https://stackoverflow.com/a/63350992/2661028
 */
export type RootState = ReturnType<typeof rootReducer>;
