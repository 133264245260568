import { VERBS } from '../../api/RequestBuilder';

export const NewsletterState: CVL.IStateSchema = {
  entity: 'Newsletter',
  actions: {
    create: {
      value: true,
      onSuccess: ['NEWSLETTER_LIST_REQUEST'],
      api: {
        verb: VERBS.post,
        endpoint: '/news/letters',
        auth: true
      }
    },
    list: {
      value: true,
      api: {
        verb: VERBS.get,
        endpoint: '/news/letters?status=:status&searchKey=:searchKey',
        auth: true
      }
    },
    update: {
      value: true,
      onSuccess: ['NEWSLETTER_LIST_REQUEST'],
      api: {
        verb: VERBS.patch,
        endpoint: '/news/letters/:id',
        auth: true
      }
    },
    delete: {
      value: true,
      onSuccess: ['NEWSLETTER_LIST_REQUEST'],
      api: {
        verb: VERBS.delete,
        endpoint: '/news/letters/:id',
        auth: true
      }
    }
  }
};
