import { useMsal } from '@azure/msal-react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../components/Button'; // Reuse your Button component.
import Form from '../../components/Form';
import { ERROR_TYPE } from '../../constants/Errors.ts';
import { useAuth } from '../../providers/AuthProvider.tsx';
import { useToast } from '../../providers/ToastProvider.tsx';
import { RootState } from '../../redux/reducers';
import { states } from '../../redux/states/StateBuilder';

const LoginPage: React.FC = () => {
  const dispatch = useDispatch();
  const { login } = useAuth();
  const { instance } = useMsal();
  const { showToast } = useToast();
  const [loginAttempted, setLoginAttempted] = useState(false);

  const msalAuth = useSelector((state: RootState) => state.createMsalAuth);

  const handleOutlookLogin = async () => {
    try {
      const response = await instance.loginPopup();
      const idToken = response.idToken;
      if (!response || !idToken) {
        showToast('error', ERROR_TYPE.LOGIN_ERROR);
        return;
      }
      setLoginAttempted(true);
      dispatch({
        type: states.createMsalAuth.constants.request,
        payload: {
          email: response.account.username,
          token: idToken
        }
      });
    } catch (error) {
      console.error('Error during Outlook login:', error);
      showToast('error', ERROR_TYPE.LOGIN_ERROR);
      setLoginAttempted(false);
    }
  };

  useEffect(() => {
    if (!loginAttempted || msalAuth.loading) return; // Skip if no login attempt has been made.
    if (msalAuth.success && msalAuth.data) {
      const user = msalAuth.data as CVL.IAuth;
      login(user); // Delegate login to AuthProvider
      setLoginAttempted(false); // Reset loginAttempted after success
    } else if (msalAuth.error || msalAuth.errors) {
      showToast('error', msalAuth.message || ERROR_TYPE.LOGIN_ERROR);
      setLoginAttempted(false);
    } else {
      showToast('error', ERROR_TYPE.LOGIN_ERROR);
    }
  }, [msalAuth, login, loginAttempted]);

  const handleLogin = (values: Record<string, string>) => {
    console.log('Form Submitted:', values);
    // API call for login can go here
  };

  const CustomOutlookIcon = () => (
    <img src="/assets/ms_outlook.png" alt="Outlook Icon" className="h-5 w-5" />
  );

  return (
    <div className="bg-gray-50 flex min-h-screen flex-col items-center bg-[url('/login-background.svg')] py-[6.7em]">
      <div className="w-full max-w-md">
        {/* Logo */}
        <div className="mb-6 text-center">
          <img
            src="/cvl-logo-blue.svg"
            alt="Logo"
            className="mx-auto h-16 w-32"
          />
          <h1 className="text-gray-900 text-2xl font-semibold">
            Log in to your <br />
            <span className="text-[#8a95aa]">Info Hub</span> account
          </h1>
          <p className="text-base leading-[18px] text-[#475467]">
            Welcome back! Please enter your details.
          </p>
        </div>
      </div>
      <div className="w-full max-w-md rounded-lg bg-white p-8 shadow-lg">
        {/* Form */}
        <Form
          fields={[
            {
              name: 'email',
              type: 'email',
              label: 'Email',
              placeholder: 'Enter your email',
              required: true
            },
            {
              name: 'password',
              type: 'password',
              label: 'Password',
              placeholder: 'Password...',
              required: true
            }
          ]}
          onSubmit={handleLogin}
          submitLabel="Sign in"
        />
        {/* Divider */}
        <div className="relative my-4">
          <div className="absolute inset-0 flex items-center">
            <div className="border-gray-300 w-full border-t"></div>
          </div>
          <div className="relative flex justify-center text-sm">
            <span className="text-gray-500 bg-white px-2">or</span>
          </div>
        </div>

        {/* Sign in with Outlook */}
        <div>
          <Button
            label={
              <div className="flex items-center space-x-2">
                <CustomOutlookIcon />
                <span className="font-inter text-left text-base font-semibold leading-6">
                  Sign in with Outlook
                </span>
              </div>
            }
            variant="secondary"
            className="w-full rounded-lg px-4 py-2"
            onClick={handleOutlookLogin}
          />
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
