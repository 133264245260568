import { isEmpty, isNil, startsWith } from 'lodash';
import * as history from 'history';

export default history.createBrowserHistory();

/**
 * Call this function to load state from browser local storage
 */
export function loadFromLocalStorage(key: string) {
  try {
    const serializedSate: any = localStorage.getItem(key);
    if (isEmpty(serializedSate)) {
      return undefined;
    }
    return JSON.parse(serializedSate);
  } catch (error) {
    return error;
  }
}

export function saveIntoLocalStorage(key: any, value: any) {
  try {
    localStorage.setItem(key, value);
  } catch (error) {
    console.log(error);
    return null;
  }
}

export const formatedUrl = (url: string) => {
  let formatedLink;
  if (!startsWith(url, 'https') || !startsWith(url, 'http')) {
    formatedLink = `https://${url}`;
  }
  return formatedLink;
};

export const stateErrMessage = (...states: any) => {
  const res = { hasError: false, errorMsg: '' };
  for (const state of states) {
    if (state.error || state.errors) {
      res.hasError = true;
      res.errorMsg = state.message || state.errors?.[0]?.message;
      return res;
    }
  }
  return res;
};

export function errorFormater(error: any) {
  if (isNil(error)) {
    return null;
  }

  if (error instanceof Array) {
    return error;
  }

  if (error instanceof Object)
    return [
      {
        field: error.field,
        message: error.message
      }
    ];

  return null;
}
