import React, { useEffect } from 'react';
import {
  LuBookOpen,
  LuBuilding2,
  LuCalendarPlus,
  LuPenLine
} from 'react-icons/lu';
import PageHeader from '../../components/PageHeader';
import { Link } from 'react-router-dom';
import EventCard from '../events/eventCard';
import { useAuth } from '../../providers/AuthProvider';
import NewsfeedCard from './feedCard';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers';
import { states } from '../../redux/states/StateBuilder';
import { format } from 'date-fns';
import { getRandomUniqueColor } from '../../utils/ColorPicker';
import { isEmpty } from 'lodash';
import ImageContentLoader from '../../components/CardImageContentLoader';

const HomePage: React.FC = () => {
  const { auth } = useAuth();
  const { listFeeds } = useSelector((state: RootState) => state);
  const { listEvents } = useSelector((state: RootState) => state);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: states.listFeeds.constants.request,
      params: { status: 'published' }
    });

    dispatch({
      type: states.listEvents.constants.request,
      params: {
        type: 'upcoming'
      }
    });
  }, []);

  console.log('listEvents', listEvents.data);

  return (
    <>
      <PageHeader
        title={`Welcome back, ${auth?.firstName}`}
        description="Explore recent activities and manage company content effortlessly."
      />

      {/* Quick Actions Section */}
      <div>
        <h2 className="mb-4 border-b border-brand-50 py-4 text-lg font-semibold text-brand-500">
          Quick actions
        </h2>
        <div className="grid grid-cols-2 gap-4">
          <Link to={'/news-feed'}>
            <button className="flex w-full items-center gap-3 rounded-lg border border-brand-50 p-4 hover:shadow-md">
              <div className="flex h-12 w-12 items-center justify-center rounded-lg border bg-brand-50">
                <LuPenLine className="h-6 w-6 text-brand-600" />
              </div>
              <div className="flex flex-col items-start">
                <p className="text-sm font-medium text-brand">
                  Add news feed post
                </p>
                <p className="text-xs font-light text-brand-200">
                  Share updates with employees
                </p>
              </div>
            </button>
          </Link>
          {/* Create new event */}
          <Link to={'/events'}>
            <button className="flex w-full items-center gap-3 rounded-lg border border-brand-50 p-4 hover:shadow-md">
              <div className="flex h-12 w-12 items-center justify-center rounded-lg border bg-brand-50">
                <LuCalendarPlus className="h-6 w-6 text-brand-600" />
              </div>
              <div className="flex flex-col items-start">
                <p className="text-sm font-medium text-brand">
                  Create new event
                </p>
                <p className="text-xs font-light text-brand-200">
                  Plan and schedule company events
                </p>
              </div>
            </button>
          </Link>
          {/* Create new newsletter */}
          <Link to={'/newsletters'}>
            <button className="flex w-full items-center gap-3 rounded-lg border border-brand-50 p-4 hover:shadow-md">
              <div className="flex h-12 w-12 items-center justify-center rounded-lg border bg-brand-50">
                <LuBookOpen className="h-6 w-6 text-brand-600" />
              </div>
              <div className="flex flex-col items-start">
                <p className="text-sm font-medium text-brand">
                  Create new newsletter
                </p>
                <p className="text-xs font-light text-brand-200">
                  Keep teams informed with a newsletter
                </p>
              </div>
            </button>
          </Link>

          {/* Add new subsidiary */}
          <Link to={'/subsidiaries'}>
            <button className="flex w-full items-center gap-3 rounded-lg border border-brand-50 p-4 hover:shadow-md">
              <div className="flex h-12 w-12 items-center justify-center rounded-lg border bg-brand-50">
                <LuBuilding2 className="h-6 w-6 text-brand-600" />
              </div>
              <div className="flex flex-col items-start">
                <p className="text-sm font-medium text-brand">
                  Add new subsidiary
                </p>
                <p className="text-xs font-light text-brand-200">
                  Add new subsidiary details
                </p>
              </div>
            </button>
          </Link>
        </div>
      </div>

      {/* Recent Feed News Section */}
      <div className="grid grid-cols-4 gap-4">
        <section className="col-span-3 mt-8">
          <h2 className="mb-4 text-lg font-semibold text-brand-500">
            Recent feed news
          </h2>

          {/* Recent newsfeeds cards */}
          <div className="grid max-h-[440px] grid-cols-2 gap-4 overflow-y-auto">
            {listFeeds.loading ? (
              <ImageContentLoader />
            ) : (
              ((listFeeds?.data as any[]) || []).map((item, index) => [
                <NewsfeedCard
                  key={index}
                  title={item.title || ''}
                  imageUrl={item.imageUrl || ''}
                  date={format(item?.updatedAt, 'dd MMM yyyy')}
                  description={item?.caption ?? ''}
                />
              ])
            )}
          </div>
        </section>

        <section className="col-span-1 mt-9 w-full gap-4">
          <h2 className="mb-4 font-semibold text-brand-300">Upcoming Events</h2>

          <div className="grid max-h-[440px] gap-4 overflow-y-auto">
            {' '}
            {!isEmpty(listEvents.data) ? (
              ((listEvents.data as any[]) || []).map((item, index) => [
                <EventCard
                  key={index}
                  leftBarColor={getRandomUniqueColor()}
                  eventName={item.title || ''}
                  location={item.location || ''}
                  month={item.date}
                  date={item.date}
                  startTime={item.startTime}
                  endTime={item.endTime}
                />
              ])
            ) : (
              <div className="mb-4 flex w-full rounded-lg border border-brand-50 bg-white p-4 shadow-md">
                <div className="mr-4 mt-2 h-[80px] w-[6px] rounded-full bg-gradient-to-b from-gray to-brand-100"></div>
                <div className="flex w-full flex-col items-center justify-center text-center">
                  <h1 className="py-2 text-sm font-semibold text-brand-300">
                    No Upcoming Events
                  </h1>
                  <p className="mt-2 text-sm font-light text-brand-300">
                    Stay tuned! Events will be displayed here once scheduled.
                  </p>
                </div>
              </div>
            )}
          </div>
        </section>
      </div>
    </>
  );
};

export default HomePage;
