import { MsalAuthState } from './MsalAuthState';
import { EventsState } from './EventsState';
import { UsersState } from './UserState';
import { DepartmentsState } from './DepartmentState';
import { CompaniesState } from './CompanyState';
import { FeedsState } from './FeedsState';
import { ImageState } from './FileImageState';
import { NewsletterState } from './NewsletterState';

export const StateCollection: CVL.IStateSchema[] = [
  MsalAuthState,
  UsersState,
  EventsState,
  DepartmentsState,
  CompaniesState,
  FeedsState,
  ImageState,
  NewsletterState
];
