import React from 'react';
import { IoLocation } from 'react-icons/io5';
import { FaRegClock } from 'react-icons/fa';
import _ from 'lodash';
import { format } from 'date-fns';

// Define the props interface
interface EventCardProps {
  leftBarColor: string;
  eventName: string;
  location: string;
  date: string;
  startTime: string;
  endTime: string;
  month: string;
}

const EventCard: React.FC<EventCardProps> = ({
  leftBarColor,
  eventName,
  location,
  date,
  startTime,
  endTime,
  month
}) => {
  return (
    <>
      <p className="text-sm font-bold text-[#00000099]">
        {format(month, 'MMMM')}
      </p>
      <div className="mb-2 flex w-full rounded-lg border border-brand-50 p-2 text-brand-300">
        <div
          className={`mr-2 mt-2 flex h-[80px] w-[5px] rounded-lg`}
          style={{ backgroundColor: leftBarColor ?? 'bg-brand-500' }}
        ></div>
        <div className="text-brand-300">
          <h1 className="py-2 text-sm font-semibold">{eventName}</h1>
          <p className="text-xs font-light text-brand-100">
            {format(date, 'EEEE, dd MMM yyyy')}
          </p>
          <div className="my-3 flex flex-col gap-3 sm:flex-row sm:justify-between">
            <div className="flex items-center gap-2">
              <IoLocation />
              <p className="text-[10px] font-light text-brand-100">
                {_.capitalize(location)}
              </p>
            </div>
            <div className="flex items-center gap-2">
              <FaRegClock />
              <p className="text-[10px] font-light text-brand-100">
                {startTime}
              </p>
              <p className="text-brand-100">{'-'}</p>
              <p className="text-[10px] font-light text-brand-100">{endTime}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventCard;
