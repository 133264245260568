import { VERBS } from '../../api/RequestBuilder';

export const DepartmentsState: CVL.IStateSchema = {
  entity: 'Departments',
  actions: {
    create: {
      value: true,
      api: {
        verb: VERBS.post,
        endpoint: '/departments',
        auth: true
      }
    },
    list: {
      value: true,
      api: {
        verb: VERBS.get,
        endpoint: '/departments?searchKey=:searchKey',
        auth: true
      }
    },
    update: {
      value: true,
      api: {
        verb: VERBS.patch,
        endpoint: '/departments/:id',
        auth: true
      }
    },
    delete: {
      value: true,
      api: {
        verb: VERBS.delete,
        endpoint: '/departments/:id',
        auth: true
      }
    }
  }
};
