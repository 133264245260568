import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../providers/AuthProvider';
import { useToast } from '../providers/ToastProvider';

export const ProtectedRoute: React.FC = () => {
  const { auth, isAuthenticated } = useAuth();
  const { showToast } = useToast();
  const [toastShown, setToastShown] = useState(false);

  useEffect(() => {
    if (!isAuthenticated && !toastShown) {
      showToast('error', 'You need to login to continue.');
      setToastShown(true);
    }
  }, [isAuthenticated, showToast, toastShown]);

  if (!isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  if (auth && auth.role !== 'admin') {
    return <Navigate to={'/'} replace />;
  }

  return <Outlet />;
};
