import React from 'react';

interface ProfileAvatarProps {
  profileImage?: string;
  firstName: string;
  lastName: string;
  size?: number;
}

const ProfileAvatar: React.FC<ProfileAvatarProps> = ({
  profileImage,
  firstName,
  lastName,
  size = 12
}) => {
  return (
    <div
      className={`bg-gray-200 m-3 flex items-center justify-start rounded-full ring-2 ring-white`}
    >
      {profileImage ? (
        <img
          className={`my-1 mr-4 h-12 w-12 rounded-full object-cover`}
          src={profileImage}
          alt={`${firstName} ${lastName}`}
        />
      ) : (
        <img
          className={`my-1 mr-4 h-12 w-12 rounded-full size-${size}`}
          src="/assets/user_avatar.png"
          alt={`${firstName} ${lastName}`}
        />
      )}
      <span className="font-semibold">
        {firstName} {lastName}
      </span>
    </div>
  );
};

export default ProfileAvatar;
