import React from 'react';
import { Link } from 'react-router-dom';

const NotFoundPage: React.FC = () => {
  return (
    <div className="bg-gray-100 flex min-h-screen flex-col items-center justify-center text-center">
      <h1 className="text-gray-800 text-6xl font-bold">404</h1>
      <p className="text-gray-600 mt-2 text-xl">
        Oops! The page you are looking for does not exist.
      </p>
      <Link
        to="/"
        className="mt-4 rounded-md bg-brand-600 px-4 py-2 text-white hover:bg-brand-700"
      >
        Go Back Home
      </Link>
    </div>
  );
};

export default NotFoundPage;
