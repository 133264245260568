import { isBoolean, isEmpty, isEqual, map } from 'lodash';
import { call, put, takeLatest } from 'redux-saga/effects';
import { request } from '../../api/RequestBuilder.ts';
import history, { errorFormater } from '../../utils';
import { initialState } from '../reducers/ReducerBuilder.ts';
import { stateSchema } from '../states/StateBuilder.ts';

const states = stateSchema();

function dispatcher(
  type: string,
  payload: Array<object> | object | null = null,
  params: string[] = []
) {
  return {
    type,
    payload,
    params
  };
}

/**
 *
 * @param endpoint a string to be formatted
 * @param params an object of parametters to be appended to the string
 * @returns formatted string
 */
function formatParamatezedEndpoint(endpoint: string, params: any) {
  /**
   * When a @url like /employee?pageNumber=:pageNumber&search=:searchKey formulated without passing the
   * @params object like {pageNumber:0, searchKey:doe}, it turns out to be an invalid url.
   * However, if the @params was not provided at all, we should send an empty string in every place of a param
   * So we replace ":paramName" placeholder with an empty string
   *
   * The result would be /employee?pageNumber=&search=
   */
  if (isEmpty(endpoint)) throw new Error(`Endpoint is not defined`);

  const paramPattern = /:\w+/g;
  const searchParams = endpoint.match(paramPattern);
  if (searchParams && searchParams.length > 0) {
    for (const searchParam of searchParams) {
      const param = searchParam.split(':')[1];

      endpoint = endpoint.replace(searchParam, params[param] || '');
    }
  }
  return endpoint;
}

function createRequest(api: CVL.IAPI, params: object) {
  if (isEmpty(api)) throw new Error(`Api details are missing`);

  const { auth, multipart, ...rest } = api;

  const endpoint = formatParamatezedEndpoint(rest.endpoint, params);

  return (data: any) =>
    request(rest.verb as any, endpoint, data, {
      auth,
      multipart
    });
}

function buildSaga(
  actions: CVL.IConstant,
  apiData: CVL.IAPI,
  followUp: string[],
  redirect: string
) {
  return function* (props: CVL.IReduxAction<object>): Generator<any, any, any> {
    const { payload, params } = props;

    try {
      yield put(dispatcher(actions.load, { ...initialState, loading: true }));

      const api = createRequest(apiData, params);
      const response = yield call(api, payload);

      if (
        isEmpty(response) ||
        isEqual(response.success, false) ||
        !isEmpty(response?.data?.errors) ||
        isEqual(response instanceof Error, true)
      ) {
        return yield put(
          dispatcher(actions.error, {
            ...response,
            errors: errorFormater(response?.errors || response)
          })
        ) as any;
      }

      if (!isEmpty(followUp)) {
        yield put(dispatcher(actions.success, response));
        yield put(dispatcher(actions.data, response));
        yield put(dispatcher(actions.meta, response));

        // dispatch in sequence starting with sync states
        for (const flup of followUp) {
          yield* sequentialDispatcher(
            flup,
            isBoolean(response.data) ? payload : response.data,
            payload,
            params
          );
        }

        return performRedirect(redirect);
      }

      yield put(dispatcher(actions.success, response));

      if (redirect) {
        yield put(dispatcher(actions.data, response));
        return performRedirect(redirect);
      }

      return yield put(dispatcher(actions.data, response));
    } catch (error) {
      return yield put(
        dispatcher(actions.error, {
          ...(error as object),
          errors: errorFormater(error)
        })
      );
    }
  };
}

function performRedirect(url: string) {
  return !isEmpty(url) ? history.push(url) : '';
}
function* sequentialDispatcher(
  type: string,
  data: object,
  req: object,
  params: string[] = []
) {
  yield put(dispatcher(type, { ...data, ...req }, params));
}
export function combinedSagas() {
  const sagas: CVL.IBuildSaga = {};

  // filter out dumb states
  const filteredStates: CVL.IBuildState = {};

  map(Object.keys(states) || [], (s: string) => {
    const state = states[s];
    if (!isEqual(state.hasNoSideEffect, true)) filteredStates[s] = state;
  });

  return map(Object.keys(filteredStates) || [], (s: string) => {
    const state = filteredStates[s];

    sagas[s] = buildSaga(
      state.constants,
      state.api,
      state.onSuccess,
      state.urlRedirect
    );
    return takeLatest(state.constants.request, sagas[s]);
  });
}
