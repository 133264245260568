import _, { isEmpty } from 'lodash';
import React, { useRef, useState } from 'react';
import { CiCircleRemove } from 'react-icons/ci';
import { useToast } from '../providers/ToastProvider';

interface FileUploaderProps {
  title: string;
  maxFileSizeMB?: number;
  acceptedFileTypes?: string;
  maxImages?: number;
  onUploadComplete?: (urls: string[]) => void;
  onPreviewUrlsChange?: (urls: string[]) => void;
  initialValue?: string | string[] | null;
}

const FileUploader: React.FC<FileUploaderProps> = ({
  title = ' Drag or Drop Images',
  maxFileSizeMB = 2,
  acceptedFileTypes = '.jpeg,.jpg,.png,.gif',
  maxImages = 1,
  onUploadComplete,
  onPreviewUrlsChange,
  initialValue
}) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [previewUrls, setPreviewUrls] = useState<string[]>(() => {
    if (Array.isArray(initialValue)) {
      return _.compact(initialValue);
    } else if (initialValue) {
      return _.compact([initialValue]);
    }
    return [];
  });
  const [uploading, setUploading] = useState(false);
  const [uploadedUrls, setUploadedUrls] = useState<string[]>([]);
  const [isDragging, setIsDragging] = useState(false);
  const { showToast } = useToast();

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    if (files) await processFiles(files);
  };

  const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);

    const files = event.dataTransfer.files;
    if (files) await processFiles(files);
  };

  const processFiles = async (files: FileList | null) => {
    if (!files) return;

    const newFiles = Array.from(files).slice(0, maxImages - previewUrls.length);

    // Validate file size
    const validFiles = newFiles.filter(
      (file) => file.size <= maxFileSizeMB * 1024 * 1024
    );

    setUploading(true);

    const newUploadedUrls: string[] = [...uploadedUrls];

    for (const file of validFiles) {
      try {
        const formData = new FormData();
        formData.append('file', file);

        const response = await fetch(
          `${import.meta.env.VITE_INFOHUB_SERVER_URL}/file/image/upload`,
          {
            method: 'POST',
            body: formData
          }
        );

        if (response.ok) {
          const result = await response.json();

          if (result.success) {
            const { data } = result;
            const uploadedUrl = data[0];

            // Update preview URLs and notify parent
            setPreviewUrls((prev) => {
              const updatedUrls = _.compact([...prev, uploadedUrl]);
              onPreviewUrlsChange?.(updatedUrls);
              return updatedUrls;
            });

            newUploadedUrls.push(uploadedUrl);
          } else {
            showToast('error', 'Failed to upload file. Please try again.');
          }
        } else {
          showToast('error', 'Failed to upload file due to server error.');
        }
      } catch (error) {
        console.log(error);
        showToast(
          'error',
          'An unexpected error occurred while uploading the file.'
        );
      }
    }

    setUploadedUrls(newUploadedUrls);
    onUploadComplete?.(newUploadedUrls);
    setUploading(false);
  };

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleRemoveImage = (index: number) => {
    setPreviewUrls((prev) => {
      const updatedUrls = _.compact(
        prev.filter((_, itemIndex) => itemIndex !== index)
      );
      onPreviewUrlsChange?.(updatedUrls);
      return updatedUrls;
    });
  };

  const handleBrowseClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <div className="rounded-lg bg-white p-5 shadow">
      <div className="mb-4 border-b border-[#e0e0e0] pb-[8px]">
        <h2 className="text-[17px] font-[600] text-black">{title}</h2>
      </div>
      <div
        className={`relative space-y-4 ${
          isDragging ? 'border-2 border-blue-500' : ''
        }`}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        {previewUrls.length < maxImages && (
          <div
            className={`border-gray-300 flex justify-center rounded-xl bg-white p-12 ${
              uploading
                ? 'pointer-events-none opacity-50'
                : 'border border-dashed'
            } ${isEmpty(previewUrls) && 'cursor-pointer'}`}
            onClick={handleBrowseClick}
          >
            <input
              ref={fileInputRef}
              type="file"
              accept={acceptedFileTypes}
              onChange={handleFileChange}
              style={{ display: 'none' }}
              disabled={uploading || previewUrls.length >= maxImages}
              multiple
            />
            {(isEmpty(previewUrls) || previewUrls.length < maxImages) && (
              <div className="text-center">
                <span className="bg-gray-100 text-gray-800 inline-flex size-16 items-center justify-center rounded-full">
                  <svg
                    className="size-6 shrink-0"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                    <polyline points="17 8 12 3 7 8"></polyline>
                    <line x1="12" x2="12" y1="3" y2="15"></line>
                  </svg>
                </span>

                <div className="text-gray-600 mt-4 flex flex-wrap justify-center text-sm leading-6">
                  <span className="pe-1 font-medium text-[#344054]">
                    Drop your files here or
                  </span>
                  <span className="rounded-lg bg-white font-semibold text-blue-600 decoration-2 hover:text-blue-700 hover:underline">
                    browse
                  </span>
                </div>

                <p className="mt-1 text-xs font-light text-[#344054]">
                  Pick up to {maxImages} files, each up to {maxFileSizeMB}MB.
                </p>
                {uploading && (
                  <div className="inset-0 z-10 flex items-center justify-center bg-white bg-opacity-70 backdrop-blur-sm">
                    <div className="h-12 w-12 animate-spin rounded-full border-4 border-white border-t-brand-500"></div>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
        {!isEmpty(previewUrls) && (
          <div className="grid grid-cols-6 gap-1">
            {previewUrls.map((url, index) => (
              <React.Fragment key={index}>
                <div className="relative h-full w-full rounded-xl border border-none bg-white p-2">
                  <img
                    className="h-full w-full rounded-lg"
                    src={url}
                    alt={`Preview ${index + 1}`}
                  />
                </div>
                <div title="Remove">
                  <CiCircleRemove
                    color="red"
                    size={20}
                    className="cursor-pointer stroke-[0.5]"
                    onClick={() => handleRemoveImage(index)}
                  />
                </div>
              </React.Fragment>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default FileUploader;
