import React from 'react';
import { NavLink } from 'react-router-dom'; // If you're using react-router for navigation
import {
  FaBuilding,
  FaCalendarAlt,
  FaHome,
  FaRegNewspaper,
  FaUsers
} from 'react-icons/fa';
import { TbLogout } from 'react-icons/tb';
import { LuChevronDown, LuChevronRight, LuLayers } from 'react-icons/lu';
import { FcDepartment } from 'react-icons/fc';
import { RiOrganizationChart } from 'react-icons/ri';
import { useAuth } from '../providers/AuthProvider';

interface SidebarItem {
  label: string;
  icon: React.ReactNode;
  path: string;
  subItems?: SidebarItem[];
}

const sidebarItems: SidebarItem[] = [
  { label: 'Home', icon: <FaHome />, path: '/home' },
  { label: 'News feed posts', icon: <FaRegNewspaper />, path: '/news-feed' },
  {
    label: 'Newsletters',
    icon: <LuLayers />,
    path: '/newsletters'
  },
  { label: 'Events', icon: <FaCalendarAlt />, path: '/events' },
  {
    label: 'Company',
    icon: <FaBuilding />,
    path: '#', // No direct path
    subItems: [
      {
        label: 'Subsidiaries',
        icon: <RiOrganizationChart />,
        path: '/subsidiaries'
      },
      { label: 'Departments', icon: <FcDepartment />, path: '/departments' }
    ]
  },
  { label: 'Team', icon: <FaUsers />, path: '/team' }
];

const Sidebar: React.FC = () => {
  const { auth, logout } = useAuth();
  return (
    <aside className="flex h-screen w-64 flex-col bg-brand-500 text-white">
      <div className="flex px-4 py-4">
        <img src="/cvl-logo-white.svg" alt="Logo" className="h-12 w-40" />
      </div>
      <nav className="flex-1 px-4 py-4">
        <ul className="space-y-2">
          {sidebarItems.map((item) => (
            <li key={item.label} className="group">
              {!item.subItems ? (
                <NavLink
                  to={item.path}
                  className={({ isActive }) =>
                    `flex items-center gap-3 rounded-lg p-2 ${
                      isActive ? 'bg-brand-300' : 'hover:bg-brand-400'
                    }`
                  }
                >
                  {item.icon}
                  <span>{item.label}</span>
                </NavLink>
              ) : (
                <details className="group">
                  <summary className="flex cursor-pointer items-center justify-between rounded-lg p-2 hover:bg-brand-300 [&::-webkit-details-marker]:hidden">
                    <div className="flex items-center gap-3">
                      {item.icon}
                      <span>{item.label}</span>
                    </div>
                    <div className="transition-transform duration-300 group-open:rotate-180">
                      <LuChevronRight className="h-5 w-5 opacity-60 group-open:hidden" />
                      <LuChevronDown className="hidden h-5 w-5 opacity-60 group-open:block" />
                    </div>
                  </summary>
                  <ul className="ml-4 space-y-1">
                    {item.subItems.map((subItem) => (
                      <li key={subItem.label}>
                        <NavLink
                          to={subItem.path}
                          className={({ isActive }) =>
                            `flex items-center gap-3 rounded-lg p-2 ${
                              isActive ? 'bg-brand-300' : 'hover:bg-brand-400'
                            }`
                          }
                        >
                          {subItem.icon}
                          <span>{subItem.label}</span>
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </details>
              )}
            </li>
          ))}
        </ul>
      </nav>
      <div className="flex items-center space-x-3 border-t border-brand-300 px-2 py-4">
        <img
          className="h-12 w-12 rounded-full object-cover"
          src={auth?.profileImage || '/assets/user_avatar.png'}
          alt="Profile image"
        />
        <div className="flex flex-1 items-center justify-between">
          <div className="flex flex-col">
            <p className="text-gray-900 text-sm font-medium">{`${auth?.firstName} ${auth?.lastName}`}</p>
            <p className="truncate text-xs text-gray">{auth?.email}</p>
          </div>
          <button
            onClick={logout}
            className="text-gray-500 flex items-center justify-center transition hover:text-brand-200 focus:outline-none"
            aria-label="Logout"
          >
            <TbLogout size={20} className="h-5 w-5" />
          </button>
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
