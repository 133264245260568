import { ActionButtons } from '../../../components/ActionButtons';
import * as Yup from 'yup';

export const companyColumns = (actions: any) => [
  {
    name: 'Name',
    selector: (row: any) => row?.name
  },
  {
    name: 'Website',
    selector: (row: any) => row?.website
  },
  {
    name: 'Parent Company',
    selector: (row: any) => (row?.parent?.name ? row?.parent?.name : 'CVL')
  },
  {
    name: 'Actions',
    type: 'actions',
    cell: (row: any) => [
      <ActionButtons row={row} key={row.id} actions={actions} />
    ]
  }
];

export const companyValidationSchema = Yup.object().shape({
  name: Yup.string()
    .transform((value) => value.trim())
    .min(2, 'The first name is too short!')
    .required('First name is required'),
  website: Yup.string()
    .transform((value) => value.trim())
    .required('The website URL is required'),
  description: Yup.string()
    .transform((value) => value.trim())
    .required('The description is required')
});
