import axios, { AxiosInstance } from 'axios';
import { toLower } from 'lodash';
import { saveIntoLocalStorage } from '../utils';

export const VERBS = Object.freeze({
  get: 'GET',
  post: 'POST',
  put: 'PUT',
  patch: 'PATCH',
  delete: 'DELETE'
} as const);
interface RequestOptions {
  auth?: boolean;
  multipart?: boolean;
}
export const request = async (
  method: keyof typeof VERBS,
  url: string,
  data: any = undefined,
  options: RequestOptions = { auth: false, multipart: false }
): Promise<any> => {
  try {
    const instance = createAxiosInstance(options);
    const response = await instance.request({ url, method, data });

    const token = response.headers?.['X-Auth-Token'];
    if (token) saveIntoLocalStorage('userToken', token);

    return response.data;
  } catch (err) {
    return handleRequestError(err);
  }
};

function createAxiosInstance(options: RequestOptions): AxiosInstance {
  try {
    const { auth = false, multipart = false } = options;
    const baseURL = import.meta.env.VITE_INFOHUB_SERVER_URL;

    const headers: Record<string, string> = {
      'Content-Type': multipart
        ? 'application/x-www-form-urlencoded'
        : 'application/json'
    };
    const userAuth = JSON.parse(localStorage.getItem('auth')!) as CVL.IAuth;
    if (auth && userAuth) {
      headers['X-Auth-Token'] = userAuth.token!;
    }

    return axios.create({
      baseURL,
      headers
    });
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error?.message);
    }
    throw new Error('Unknown Axios instance');
  }
}

function handleRequestError(error: unknown): any {
  if (!axios.isAxiosError(error) || !error.response) {
    throw new Error(
      error instanceof Error ? error.message : 'Unexpected error occurred'
    );
  }

  const { response } = error;
  const normalizedMessage = toLower(
    response.data?.message || response.data?.error?.message || ''
  );

  // Handle Internal Server Error
  if (response.status >= 500 && normalizedMessage === 'internal server error') {
    return {
      ...response.data,
      message: `
        Connection timed out. \n
        Please refresh the page to continue using Info Hub. \n
        If the problem persists, please contact our customer success team or email us at cs@huzahr.com.
      `
    };
  }

  // Return other error responses
  return response.data;
}
