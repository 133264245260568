export const getRandomUniqueColor = () => {
  const leftBarColor = [
    '#00C7BE',
    'blue',
    'yellow',
    'green',
    'red',
    'orange',
    'purple'
  ];

  let remainingColors = [...leftBarColor];

  if (remainingColors.length === 0) {
    // Refill and shuffle the array when all colors are used
    remainingColors = [...leftBarColor];
  }

  const randomIndex = Math.floor(Math.random() * remainingColors.length);
  const color = remainingColors[randomIndex];
  remainingColors.splice(randomIndex, 1);
  return color;
};
