import { VERBS } from '../../api/RequestBuilder';

export const MsalAuthState: CVL.IStateSchema = {
  entity: 'Msal Auth',
  actions: {
    create: {
      value: true,
      api: {
        verb: VERBS.post,
        endpoint: '/auth/outlook',
        auth: false
      }
    },
    submit: {
      value: true,
      api: {
        verb: VERBS.post,
        endpoint: '/auth/logout',
        auth: false
      }
    }
  }
};
