import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers';
import FileUploader from '../../components/ImageUploader';
import { useState } from 'react';
import { states } from '../../redux/states/StateBuilder';
import { feedValidationSchema } from './columns/newsfeedsColumns';

interface InitialValuesType {
  id: any;
  title: string;
  caption: string;
  imageUrl: any;
}

const AddNewFeedForm = ({
  initialValues,
  setActive
}: {
  initialValues: InitialValuesType;
  setActive: (newState: number) => void;
}) => {
  const { createFeeds } = useSelector((state: RootState) => state);
  const { updateFeeds } = useSelector((state: RootState) => state);
  const [status, setStatus] = useState<string>('draft'); // Default status is 'draft'

  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: feedValidationSchema,
    onSubmit: (values) => {
      const { id, ...restValues } = values;
      if (id) {
        dispatch({
          type: states.updateFeeds.constants.request,
          params: { id, status }, // By defualt on update fetch publised feeds
          payload: { ...restValues, imageUrl: previewUrls[0], status }
        });
      } else {
        dispatch({
          type: states.createFeeds.constants.request,
          params: { status },
          payload: { ...restValues, imageUrl: previewUrls[0], status }
        });
      }
    }
  });

  const [previewUrls, setPreviewUrls] = useState<string[]>([
    initialValues.imageUrl
  ]);
  const handlePreviewUrlsChange = (urls: string[]) => {
    setPreviewUrls(urls);
  };

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="m-auto w-full rounded-lg bg-[#F8F9FA] p-8 shadow-md"
    >
      <div className="space-y-6">
        {/* Title Input */}
        <div className="flex flex-col space-y-2">
          <label
            htmlFor="title"
            className="text-sm font-semibold text-[#344054]"
          >
            Title
          </label>
          <input
            id="title"
            name="title"
            type="text"
            placeholder="Feed title"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.title}
            className={`text-gray-700 h-[40px] w-full rounded-md border px-3 text-sm placeholder:text-neutral-400 focus:shadow-lg focus:outline-none focus:ring-1 ${
              formik.errors.title && formik.touched.title
                ? 'border-red-500 focus:ring-red-500'
                : 'border-slate-200 focus:ring-indigo-500'
            }`}
          />
          {formik.errors.title && formik.touched.title && (
            <span className="text-xs text-red-500">{formik.errors.title}</span>
          )}
        </div>
        <FileUploader
          onPreviewUrlsChange={handlePreviewUrlsChange}
          title={'Add Image'}
          initialValue={initialValues.imageUrl}
        />
        {/* Caption Input */}
        <div className="flex flex-col space-y-2">
          <label
            htmlFor="caption"
            className="text-sm font-semibold text-[#344054]"
          >
            Caption
          </label>
          <textarea
            id="caption"
            name="caption"
            placeholder="Feed caption"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.caption}
            className={`text-gray-700 min-h-[120px] w-full rounded-md border px-3 text-sm placeholder:text-neutral-400 focus:shadow-lg focus:outline-none focus:ring-1 ${
              formik.errors.caption && formik.touched.caption
                ? 'border-red-500 focus:ring-red-500'
                : 'border-slate-200 focus:ring-indigo-500'
            }`}
          />
          {formik.errors.caption && formik.touched.caption && (
            <span className="text-xs text-red-500">
              {formik.errors.caption}
            </span>
          )}
        </div>

        <div className="mt-6 flex w-full justify-end">
          <div className="my-4 flex w-1/4 gap-3 text-sm font-semibold">
            {' '}
            <button
              id="draft"
              type="button"
              onClick={() => {
                setStatus('draft');
                setActive(2);
                formik.handleSubmit();
              }}
              className="w-full rounded-lg border border-brand-50 bg-none p-2.5 text-brand transition duration-300 ease-in-out hover:bg-slate-100"
            >
              {(createFeeds.loading || updateFeeds.loading) &&
              status === 'draft' ? (
                <div className="h-6 w-6 animate-spin rounded-full border-b-2 border-brand-500" />
              ) : (
                'Save as draft'
              )}
            </button>
            <button
              id="published"
              type="button"
              onClick={() => {
                setStatus('published');
                setActive(1);
                formik.handleSubmit();
              }}
              className="flex w-full justify-center rounded-lg border border-brand-50 bg-brand p-2.5 text-white transition duration-300 ease-in-out hover:bg-brand-400"
            >
              {(createFeeds.loading || updateFeeds.loading) &&
              status === 'published' ? (
                <div className="h-6 w-6 animate-spin rounded-full border-b-2 border-current" />
              ) : (
                'Publish'
              )}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddNewFeedForm;
