import React, { ReactNode, createContext, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface AuthContextType {
  auth: CVL.IAuth | null;
  isAuthenticated: boolean;
  login: (authData: CVL.IAuth) => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children
}) => {
  const navigate = useNavigate();
  // Initialize auth state from localStorage
  const [auth, setAuth] = useState<CVL.IAuth | null>(() => {
    try {
      const storedAuth = localStorage.getItem('auth');
      return storedAuth ? JSON.parse(storedAuth) : null;
    } catch {
      return null;
    }
  });
  const login = (authData: CVL.IAuth) => {
    if (authData) {
      setAuth(authData);
      localStorage.setItem('auth', JSON.stringify(authData));
      navigate('/home', { replace: true });
    }
  };

  const logout = () => {
    setAuth(null);
    localStorage.removeItem('auth');
    navigate('/', { replace: true });
  };

  const isTokenExpired = (token?: string) => {
    if (!token) return true; // Treat missing tokens as expired
    try {
      const { exp } = JSON.parse(atob(token.split('.')[1])); // Decode JWT payload
      return Date.now() >= exp * 1000; // Compare current time with `exp` in ms
    } catch {
      return true; // Treat malformed tokens as expired
    }
  };

  const isAuthenticated = !!auth?.token && !isTokenExpired(auth?.token);

  return (
    <AuthContext.Provider value={{ auth, isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
