import React from 'react';

interface PageHeaderProps {
  title: string;
  description?: string;
  className?: string; // Optional additional class names
  titleHeader?: string; // Optional additional class names
}

const PageHeader: React.FC<PageHeaderProps> = ({
  title,
  description,
  className = '',
  titleHeader = 'text-3xl'
}) => {
  return (
    <div className={`container ${className}`}>
      <div className="mb-1 flex">
        <h1
          className={`py-1 ${titleHeader} font-semibold leading-9 text-[#101828]`}
        >
          {title}
        </h1>
      </div>
      <p className="mb-6 text-base font-light leading-6 text-[#475467]">
        {description}
      </p>
    </div>
  );
};

export default PageHeader;
