import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers';
import { states } from '../../redux/states/StateBuilder';
import { FiEdit2 } from 'react-icons/fi';
import { HiOutlineTrash } from 'react-icons/hi';
import PageHeader from '../../components/PageHeader';
import DataTable from 'react-data-table-component';
import { newsfeedColumns } from './columns/newsfeedsColumns';
import { customStyles } from '../team';
import AddNewFeedForm from './addNewFeedForm';
import { IoIosArrowRoundBack } from 'react-icons/io';
import DeleteModal from '../../components/ConfirmActionModal';
import SearchBar from '../../components/SearchBar';
import { useResetState } from '../../utils/hooks/useResetState';

const FeedsPage: React.FC = () => {
  const { listFeeds } = useSelector((state: RootState) => state);
  const { deleteFeeds } = useSelector((state: RootState) => state);
  const { updateFeeds } = useSelector((state: RootState) => state);
  const { createFeeds } = useSelector((state: RootState) => state);

  const dispatch = useDispatch();
  const [active, setActive] = useState(1);
  const [showForm, setShowForm] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteItem, setDeleteItem] = useState(null);
  const [initialValues, setInitialValues] = useState({
    id: null,
    title: '',
    caption: '',
    imageUrl: ''
  });

  useEffect(() => {
    dispatch({
      type: states.listFeeds.constants.request,
      params: { status: active === 1 ? 'published' : 'draft' }
    });
  }, [active]);

  const editAction = (data: any) => {
    setInitialValues({
      id: data.id,
      title: data.title,
      caption: data.caption,
      imageUrl: data.imageUrl
    });
    setShowForm(true);
  };

  const deleteAction = (data: any) => {
    setDeleteItem(data.id);
    setShowDeleteModal(true);
  };

  const handleCloseForm = () => {
    setShowForm(false);
    setShowDeleteModal(false);
    setInitialValues({ id: null, title: '', caption: '', imageUrl: '' });
    setDeleteItem(null);
  };

  const actions = [
    {
      icon: <FiEdit2 className="h-[20px] w-[20px] text-[#475467]" />,
      handler: editAction,
      label: 'Edit'
    },
    {
      icon: <HiOutlineTrash className="h-[20px] w-[20px] text-[#475467]" />,
      handler: deleteAction,
      label: 'Delete'
    }
  ];

  const handleDelete = () => {
    dispatch({
      type: states.deleteFeeds.constants.request,
      params: {
        id: deleteItem,
        status: active === 1 ? 'published' : 'draft'
      }
    });
  };

  const Tabs = () => (
    <div className="m-2 w-[30%]">
      <ul
        className="relative flex list-none rounded-lg bg-[#F9FAFB] px-1.5 py-1.5"
        data-tabs="tabs"
      >
        {['Published', 'Drafts'].map((tab, index) => {
          const tabIndex = index + 1;
          const isActive = active === tabIndex;

          return (
            <div
              key={tabIndex}
              className={`mx-1 w-[90%] rounded-lg px-3 py-1 transition-all duration-300 ${
                isActive ? 'bg-white shadow-md' : ''
              }`}
            >
              <li className="z-30 flex-auto text-center">
                <button
                  className={`z-30 mb-0 flex w-full cursor-pointer items-center justify-center rounded-md border-0 bg-inherit px-0 py-2 text-sm font-medium transition-all ease-in-out ${
                    isActive ? 'font-semibold text-slate-800' : 'text-slate-600'
                  }`}
                  role="tab"
                  aria-selected={isActive}
                  onClick={() => setActive(tabIndex)}
                >
                  {tab}
                </button>
              </li>
            </div>
          );
        })}
      </ul>
    </div>
  );

  const handleSearchSubmit = (data: string) => {
    dispatch({
      type: states.listFeeds.constants.request,
      params: { status: active === 1 ? 'published' : 'draft', searchKey: data }
    });
  };

  useEffect(() => {
    if (createFeeds.success || deleteFeeds.success || updateFeeds.success) {
      handleCloseForm();
    }
  }, [createFeeds?.success, deleteFeeds.success, updateFeeds.success]);

  useResetState('createFeeds', [createFeeds.success as never]);
  useResetState('updateFeeds', [updateFeeds.success as never]);
  useResetState('deleteFeeds', [deleteFeeds.success as never]);

  return (
    <div>
      <DeleteModal
        isOpen={showDeleteModal}
        description="Are you sure you want to delete this item?"
        onConfirm={handleDelete}
        onCancel={handleCloseForm}
        loading={deleteFeeds.loading}
      />
      <PageHeader
        title="New Feed Posts"
        description="Create and manage news feeds to share with your team"
      />
      {showForm && (
        <div className="mb-8 flex items-center space-x-2 text-brand-500">
          <button className="flex items-center" onClick={handleCloseForm}>
            <IoIosArrowRoundBack className="text-2xl" />
            <p className="text-normal font-semibold">Back</p>
          </button>
        </div>
      )}

      <div className="rounded-[10px] border-[1px] border-solid border-[#EAECF0] p-4">
        <div className="flex flex-row place-content-between">
          <div>
            <h1 className="px-2 py-2 text-lg font-semibold leading-9 text-[#101828]">
              {!showForm ? 'Posts' : 'Create new news feed post'}
            </h1>
            {showForm && (
              <p className="mx-2 mb-2 text-base font-normal text-[#475467]">
                Enter all information needed to create a new post{' '}
              </p>
            )}
          </div>
          {!showForm && (
            <div className="flex items-center justify-center px-2">
              <button
                className="rounded-lg bg-brand px-4 py-2 text-white"
                onClick={() => setShowForm(true)}
              >
                {' '}
                <div className="flex items-center">
                  <span className="font-inter text-left text-base text-sm font-semibold">
                    + New feed
                  </span>
                </div>
              </button>
            </div>
          )}
        </div>

        {/* Divider between title and table */}
        <div className="mb-3 border-t border-[#EAECF0]"></div>
        {!showForm && (
          <>
            <div className="flex w-full items-center justify-between">
              <Tabs />{' '}
              <SearchBar
                placeholder="Search..."
                onSubmit={handleSearchSubmit}
              />
            </div>
            <div className="m-2 rounded-[10px] border-[1px] border-solid border-[#EAECF0]">
              <DataTable
                columns={newsfeedColumns(actions)}
                data={(listFeeds?.data as any[]) ?? []}
                customStyles={customStyles}
                pagination={true}
                paginationPerPage={5}
                fixedHeader={true}
              />
            </div>
          </>
        )}

        {showForm && (
          <AddNewFeedForm initialValues={initialValues} setActive={setActive} />
        )}
      </div>
    </div>
  );
};

export default FeedsPage;
