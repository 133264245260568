import { VERBS } from '../../api/RequestBuilder';

export const ImageState: CVL.IStateSchema = {
  entity: 'Image',
  actions: {
    upload: {
      value: true,
      api: {
        verb: VERBS.post,
        endpoint: '/file/image/upload',
        auth: true
      }
    }
  }
};
