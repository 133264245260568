import { stateSchema } from '../states/StateBuilder.ts';

export const initialState: CVL.IReduxState = {
  loading: false,
  success: false,
  message: null,
  errors: null,
  error: null,
  data: null,
  meta: null,
  payload: null
};

const states = stateSchema();

function buildReducer(actions: CVL.IConstant) {
  return (
    state = initialState,
    action: CVL.IReduxAction<CVL.IReduxState>
  ): CVL.IReduxState => {
    const { type, payload } = action;

    switch (type) {
      case `${actions.load}`:
        return { ...state, loading: payload.loading };
      case `${actions.success}`:
        return {
          ...state,
          loading: false,
          success: payload.success,
          message: payload.message || '',
          data: null,
          meta: null,
          errors: null,
          error: null,
          payload: payload
        };
      case `${actions.error}`:
        return {
          ...state,
          loading: false,
          success: (payload && payload.success) || false,
          errors: (payload && payload.errors) || payload,
          error: (payload && payload.errors) || payload,
          message: (payload && payload.message) || '',
          data: null,
          meta: null,
          payload: payload && payload
        };
      case `${actions.data}`:
        return {
          ...state,
          loading: false,
          success: (payload && payload.success) || false,
          message: payload.message || '',
          data: (payload && payload.data) || null,
          meta: (payload && payload.meta) || null,
          errors: null,
          error: null,
          payload: payload && payload
        };
      case `${actions.meta}`:
        return {
          ...state,
          loading: false,
          success: (payload && payload.success) || false,
          data: null,
          meta: (payload && payload.meta) || null,
          errors: null,
          error: null,
          payload: payload && payload
        };
      case `${actions.reset}`:
        return initialState;
      case `RESET_STORE`:
        return initialState;
      default:
        return state;
    }
  };
}

export function combinedReducers() {
  const reducers: CVL.IReducer = {};
  (Object.keys(states) || []).forEach((s): void => {
    reducers[s] = buildReducer(states[s].constants);
  });

  return reducers;
}
